import React, { Component } from 'react';
import { AppContext } from '../context';
import SignIn from './SignIn';
import Nav from './NavBar';
import Dashboard from './dashboard/Dashboard';
import ChangePassword from './ChangePassword';
import Messages from './Messages';

export default class AuthWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            oldPassword: '',
            user: '',
            message: '',
            expiration: 0,
            errorStatus: false
        };
        this.updateUsername = this.updateUsername.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
    }

    updateUsername(newUsername) {
        this.setState({username: newUsername});
    } 

    changePassword(user, oldPassword) {
        this.setState({oldPassword: oldPassword, user: user});
    }

    changeMessage(Message, Expiration, ErrorStatus) {
        console.log('changeMessage');
        this.setState({message: Message, expiration: Expiration, errorStatus: ErrorStatus});
    }

    clearMessage(){
        this.setState({message: '', expiration: 0, errorStatus: false});
    }
    
    render() {
        return(
            <div>
                <Nav authState={this.props.authState} onStateChange={this.props.onStateChange} />
                <SignIn authState={this.props.authState} clearMessage={this.clearMessage} changeMessage={this.changeMessage} changePassword={this.changePassword} onStateChange={this.props.onStateChange}/>
                {(this.props.authState === "signedIn" ? 
                    <AppContext>
                        <Dashboard/>
                    </AppContext>
                : null)}
                {(this.props.authState === "newPassword" ?
                    <ChangePassword user={this.state.user} oldPassword={this.state.oldPassword} clearMessage={this.clearMessage} changeMessage={this.changeMessage}/>
                : null)}
                <Messages message={this.state.message} expiration={this.state.expiration} errorStatus={this.state.errorStatus}/>
            </div>
        );
    }
}