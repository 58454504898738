import React, { Component, useState } from 'react';
import Input from '../fields/Input';
import {postTransaction} from '../APIResources';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { PageMargin } from '../forms/styles/formStyles';
import AJV from 'ajv';
import countries from '../../resources/countries.json';
import states from '../../resources/states_us.json';
import { DashContext } from '../../../context';
const ajv = new AJV();

function SelectBirthday(props) {
    const [ date, onChange ] = useState( null );
    
    function handleChange(value) {
        props.onChange(value);
        onChange(value);
    }

    return (
        <>
            <Calendar name="birthday" onChange={handleChange} selectRange={false} value={date}/>
        </>
    )
}

export default class PostTransacton extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            birthday: null,
            sponsorID: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.birthdayChange = this.birthdayChange.bind(this);
        this.formError = this.formError.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.inputs = this.inputs || {};
    }
    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
    }
    handleSelectChange(e) {
        this.inputs = this.inputs || {};
        this.inputs[e.target.name] = e.target.value;
    }
    async handleSubmit() {
        if(this.formError()) {
            await postTransaction(this.inputs, this.state.birthday).then((res) =>  {
                console.log(res); 
                this.setState({sponsorID: res.sponsor_id})
            }).catch((err) => {
                console.log(err);
            })
        } else {
            window.alert("Please fill out all of the required fields");
        }
    }
    formError() {
        const validator = ajv.compile(errorSchema);
        var Address = {};
        for(const el of Object.keys(errorSchema.properties.Address.properties)) {
            if(this.inputs[el] !== null && this.inputs[el] !== '' && this.inputs[el] !== undefined) {
                Address[el] = this.inputs[el];
            } else {
                Address[el] = '';
            }
        }
        const data = {
            first_name: this.inputs.first_name,
            last_name: this.inputs.last_name,
            email: this.inputs.email,
            amount: parseFloat(this.inputs.amount)
        };
        console.log(data);
        console.log(validator(data));
        return(validator(data));
    }
    birthdayChange(date) {
        this.setState({birthday: date});
    }
    static contextType = DashContext;
    render() {
        return(
            <>
                <PageMargin>
                    {Object.keys(transactionInputs).map((el) => {
                        return <Input label={el} name={transactionInputs[el]} onChange={this.handleChange}/>
                    })}
                    <br/>
                    <select onChange={this.handleSelectChange} name="country">
                        <option selected value="" label="Select Country">Select Country</option>
                        {Object.keys(countries).map((country,i) => {
                            return(<option value={countries[country].CountryCode} label={countries[country].Pretty}>{countries[country].Pretty}</option>)
                        })}
                    </select>
                    <br/>
                    <select onChange={this.handleSelectChange} name="state">
                        <option value="" label="Select State">Select State</option>
                        {Object.keys(states).map((state,i) => {
                            return(<option value={state} label={states[state]}>{states[state]}</option>)
                        })}
                    </select>
                    <br/>
                    <SelectBirthday onChange={this.birthdayChange} />
                    <br/>
                    <button style={{width:"40%", marginRight: "auto", fontSize: "20px"}} onClick={this.handleSubmit}> SUBMIT </button>
                    {(this.state.sponsorID !== null ? 
                        <button onClick={() => {this.context.changePage(2); this.context.passValue("sponsor_id", this.state.sponsorID); this.context.flipToolTip(false);}}>Get Sponsorship Information</button>
                    :
                        <></>
                    )}
                    <label>{this.state.sponsorID}</label>
                </PageMargin>
            </>
        )
    }
}


const transactionInputs = {
    "Amount" :"amount",
    "First Name": "first_name",
    "Middle Name": "middle_name",
    "Last Name": "last_name",
    "Email": "email",
    "Address 1": "street_1",
    "Address 2": "street_2",
    "City": "city",
    "Zip Code": "zip_code",
};

const errorSchema = {
    "type": "object",
    "properties": {   
        "first_name": {
            "type": "string",
            "minLength": 1,
            "maxLength": 35
        },
        "last_name": {
            "type": "string",
            "minLength": 1,
            "maxLength": 35
        }, 
        "middle_name": {
            "type": "string",
            "minLength": 1,
            "maxLength": 35
        },
        "email": {
            "type": "string",
            "minLength": 1,
            "maxLength": 255
        },
        "amount": {
            "type": "number",
            "minimum": 1,
            "maximum": 1000000
        },
        "tender": {
            "type": "string",
            "minLength": 1,
            "maxLength": 30
        },
        "Address": {
            "type": "object",
            "properties": {
                "street_1": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 100
                },
                "street_2": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 100
                },
                "city": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 30,
                },
                "state": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 4
                },
                "zip_code": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 10
                },
                "country": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 3
                },
            },
            "required": [ "street_1", "city", "state", "zip_code", "country" ]
        },
    },         
    "required": [ "first_name", "last_name", "email", "amount" ]
};