import styled from 'styled-components';
import ContextButton from '../buttons/contextButton';

export default function Info(props) {
    console.log('Obj: ', props.object);
    return (
        <Container> 
            {
                Object.keys(props.object).map((el) => {
                    return (
                        <>
                            <Row>
                                {(
                                    props.object[el] === undefined && props.object[el] === null && props.object[el] === '' ? <></> 
                                    : 
                                    <>
                                        <Label><b style={{textTransform: "uppercase"}}>{(el === 'teacher_alias' ? 'TEACHER' : el.replace(/_/g, ' '))}:</b> {props.object[el]}</Label>
                                    </>
                                )}                        
                            </Row>
                            {(el === 'teacher_alias' ?  
                                <ContextButton char="i">Names have been changed to protect the students and team in India.</ContextButton>                               
                            : 
                            <></>)}
                        </>
                    );
                })
            }
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const Label = styled.p`
    font-size: 20px;
`;