import React, { Component } from 'react';
import { Container, Column, Submit } from '../styles/formStyles';
import Input from '../../fields/Input';
import { getProjectInformation, updateProjectInformation } from '../../APIResources';
import { DashContext } from '../../../../context';
import ProjectDisplay from './projectDisplay';

export default class ProjectInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayObject: null,
            projectID: null
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    static contextType = DashContext;

    async handleClick() {
        let result = await getProjectInformation(this.inputs).catch((err) => console.log(err));
        this.setState({displayObject: result, projectID: this.inputs.project_id});
    }
    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
    }
    render() {
        return(
            <>
                <Column>
                    <h3>Get Project Information</h3>
                    <Input label="Project ID" name="project_id" onChange={this.handleChange} />
                    <br/>
                    <Submit onClick={this.handleClick}> Submit </Submit>
                </Column>
                <ProjectDisplay object={this.state.displayObject} projectID={this.state.projectID} />
            </>
        )
    }
}