import { PageMargin, Submit } from '../forms/styles/formStyles';
import React, { Component } from 'react';
import { DashContext } from '../../../context';
import Input from '../fields/Input';
import Navigation from '../forms/styles/pageNavigation';
import { findDonor } from '../APIResources';
import MainDisplay from '../displays/MainDisplay';

export default class FindDonor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
    }
    async handleSubmit(e) {
        await findDonor(this.inputs).then((res) => {
            console.log(res);
            this.setState({response: res});
        }).catch((err) => {
            console.log(err);
        })
    }

    static contextType = DashContext;

    render() {
        return(
            <>
                <PageMargin>
                    <Input label="First Name" name="first_name" onChange={this.handleChange} />                
                    <Input label="Last Name" name="last_name" onChange={this.handleChange} />
                    <Input label="Email" name="email" onChange={this.handleChange} />
                    <Submit onClick={this.handleSubmit}>Find Donor</Submit>
                </PageMargin>

                {(this.state.response !== null ? 
                    <>
                        <MainDisplay response={this.state.response}/>
                    </>
                :
                    <></>)}
            </>
        )
    }
}


