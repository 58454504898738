import React,  { Component } from 'react';
import Amplify from "aws-amplify";
import { Authenticator } from 'aws-amplify-react';
import AuthWrapper from './components/AuthWrapper';
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "ReportingAPI",
        endpoint: "https://obot0eqiuf.execute-api.us-east-2.amazonaws.com/Stage1"
      }
    ]
  }
})

/*
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
*/

class App extends Component {
  render() {
        return (
          <Authenticator hideDefault={true} amplifyConfig={awsconfig}>
              <AuthWrapper/>
          </Authenticator>
      );
    }
}

export default App;