import React, { Component } from 'react';
import { updateProjectInformation } from '../../APIResources';
import styled from 'styled-components';

export default class UpdateProjectInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
    }

    async handleClick() {
        await updateProjectInformation(this.inputs, this.props.projectID).then((res) => {
            console.log(res);
            this.props.onClick(true, this.inputs[this.props.updateItems[0]]);

        }).catch(err => {
            this.props.onClick(false, null);
            console.log(err)
        });
    }

    render() {
        return (
                <>
                    {this.props.updateItems.map((el) => {
                        return (
                            <CustomInput onChange={this.handleChange} name={el} id={el} />
                        );
                    })}
                    <Button onClick={this.handleClick}>SUBMIT CHANGES</Button>
                </>
        )
    }
}

const Button = styled.button`
    margin: auto 10px;
    height:15px;
`;
const CustomInput = styled.input`
    height: 15px;
    margin: auto 10px;
`;