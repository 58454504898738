import React, { Component } from 'react';
import styled from 'styled-components';
import NormalButton from '../buttons/FancyButton';
import Info from './SelectedInfo';
import ExpandableMap from '../../getSponsorshipInformation/expandableMap';

export default class SelectedDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objects: [],
            InfoObject: {}
        };
        this.renderButton = this.renderButton.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    static getDerivedStateFromProps(newprops, state) {
        return { objects: newprops.objects };
    }

    renderButton() {
        return (
            <>
                {this.state.objects.map((el, key) => {
                    return (<NormalButton key={key} ID={el.ID} information={el.information} alias={el.alias} stateAbbr={el.stateAbbr} name={el.name} onClick={() => {this.handleClick(key)}} />);
                })}
            </>
        );
    }
    handleClick(key) {
        this.setState({InfoObject: this.state.objects[key].InfoObject});
    }
    handleBack() {
        this.setState({InfoObject: {}});
        this.props.onBack();
    }

    render() {
        return(
            <Container active={true}>
                <BackButton onClick={this.handleBack}>&larr; BACK</BackButton>
                {this.renderButton()}
                <Info object={this.state.InfoObject} />
                
                {(this.state.InfoObject.svg_id !== null && this.state.InfoObject.svg_id !== undefined && this.state.InfoObject.svg_id !== '' ?
                    <ExpandableMap states={[ this.state.InfoObject.svg_id]} />
                :
                <></>
                )}
            </Container>
        )
    }
}

const Container = styled.div`
    width: 29%;
    margin: 0 .5%;
    height: auto;
    display: flex;
    flex-direction: column;
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
`;
const BackButton = styled.button`
    font-size: 20px;
    margin: 0 1.5% 1.5% 1.5%;
`;