import React, {Component} from 'react';
import { Auth } from 'aws-amplify';
// import { v4 as uuidv4 } from 'uuid';
import GetSponsorshipInformation from './pages/GetSponsorShipInformation';
import PostTransacton from './pages/PostTransaction';
import FindDonor from './pages/findDonor';
import { DashContext } from '../../context';
import UpdateInformation from './pages/updateInformation';
// import WriteArticle from './pages/writeArticles';
// import GetProjectInformation from './forms/getProjectInformation/getProjectInformation';
// import { updateProjectInformation } from './APIResources';
import FindOrganization from './pages/findOrganization';
// import Test from './pages/Test';
import Navigation from './forms/styles/pageNavigation';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            studentInformation: null,
            selection: null,
            sponsorshipSelection : null,
            sponsorshipInformation: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.getJwtToken = this.getJwtToken.bind(this);
        this.backButton = this.backButton.bind(this);
    }
    async getJwtToken() {
        console.log(`${(await Auth.currentSession()).getIdToken().getJwtToken()}`);
    }
    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
        console.log(this.inputs["date-range"]);
    }
    backButton() {
        this.setState({selection: null});
    }
    directoryChange() {

    }
    static contextType = DashContext;
    render() {
        return(
            <Navigation backButton={() => { this.context.backButton(); this.context.flipToolTip(true); }}>
                    <h3>{(this.context.page === 1 ? 'Post Transaction' : (this.context.page === 2 ? 'Get Students' : (this.context.page === 3 ? 'Find A Sponsor' : (this.context.page === 4 ? 'Update An Alias' : (this.context.page === 5 ? 'Find An Organization' : 'Dashboard')))))}</h3>
                    {(this.context.page === null ? <h3>Please Select A Task</h3> : <></> )}
                    {(this.context.page === null ? 
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '75%', margin: 'auto'}}>
                            <button onClick={() => this.context.changePage(1)}> Post Transaction </button>
                            <button onClick={() => this.context.changePage(2)}> Get Sponsorship Information </button>
                            <button onClick={() => this.context.changePage(3)}> Find A Sponsor </button>
                            <button onClick={() => this.context.changePage(5)}> Find An Organization </button>
                        </div> 
                        : (this.context.page === 1 ?
                            <>
                                <PostTransacton/>
                            </> 
                        : (this.context.page  === 2 ? 
                            <>
                                <GetSponsorshipInformation/>
                            </>
                        : (this.context.page  === 3 ? 
                            <>
                                <FindDonor/>
                            </>
                        : (this.context.page === 4 ? 
                            <>
                                <UpdateInformation/>
                            </>
                        : (this.context.page === 5 ?  
                            <>
                                <FindOrganization/>
                            </>
                        :
                        <></> ))))
                    ))}
                </Navigation>
        );
    }
}
