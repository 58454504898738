import { inputLabel } from '@aws-amplify/ui';
import React, { Component } from 'react';

const DashContext = React.createContext();
const AppProvider = DashContext.Provider;

class AppContext extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: null,
            pageHistory: [],
            toolTip: true,
            inputLabel: null,
            inputValue: null
        }
    }
    render() {
        return(
            <AppProvider value = {{
                page: this.state.page,
                changePage: newPage => {
                    let history = this.state.pageHistory;
                    history.push(newPage);
                    this.setState({
                        page: newPage,
                        pageHistory: history
                    });
                },
                inputLabel: this.state.inputLabel,
                inputValue: this.state.inputValue,
                passValue: (inputlabel, inputvalue) => {
                    this.setState({
                        inputLabel: inputlabel,
                        inputValue: inputvalue
                    })
                },
                backButton: () => {
                    if(this.state.pageHistory.length > 1) {
                        let history = this.state.pageHistory;
                        let pge = history[history.length - 2];
                        history.splice(history.length - 1, 1);
                        this.setState({
                            page: pge,
                            pageHistory: history
                        })
                    } else {
                        this.setState({
                            page: null,
                            pageHistory: []
                        });
                    }
                },
                toolTip: this.state.toolTip,
                flipToolTip: bool => {
                    this.setState({toolTip: bool});
                }
            }}>
                {this.props.children}
            </AppProvider>
        )
    }
} 


export { DashContext, AppContext };