import React, { Component } from 'react';
import TrayButton from '../buttons/TrayButton';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import {getSponsorInformation} from '../APIResources';
import { DashContext } from '../../../context';


//right now this only works for getting sponsor information using a sponsor_id
export default class EmbeddedRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            tried: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    async handleClick() {
        console.log(this.props.dbID);
        if(!this.state.tried) {
            setTimeout(() => {
                this.setState({tried: true, loading: true});
            }, 100);
            await getSponsorInformation(this.props.dbID).then(res => {
                console.log(res);
                setTimeout(() => {
                    this.setState({data: res, loading: false});
                }, 100);
            }).catch(err => console.log(err));
        }
    }

    static contextType = DashContext;

    render() {
        return(
            <Container>
                <TrayButton tL={this.props.tL} bL={this.props.bL} onClick={this.handleClick}>
                    {(this.state.data !== null ? 
                    <Container>
                        {Object.keys(this.state.data).map((el, key) => {
                            if(el === "sponsor_id") {
                                return (
                                    <Row>
                                        <label>{el}:</label> 
                                        <button onClick={() => { copy(this.state.data[el]) }}>Copy</button> 
                                        <button style={{cursor: 'pointer'}} onClick={() => { this.context.passValue('sponsor_id', this.state.data[el]); this.context.flipToolTip(false); this.context.changePage(2); }}> Get Sponsorship Information For This Donor </button>
                                    </Row>
                                );
                            } else if(el === "email") {
                                return (
                                    <Row>
                                        <label>{el}:</label> 
                                        <button style={{margin: '2px 10px', cursor: 'pointer'}} onClick={() => {copy(this.state.data[key])}}> Copy </button>
                                    </Row>
                                );
                            } else {
                                return (
                                    <Row>
                                        <label>{el}: {this.state.data[el]}</label>
                                    </Row>
                                );
                            }
                        })}
                    </Container>
                    :
                    <Container>
                        {(this.state.loading ? <p>Loading</p> : <></>)}
                    </Container>
                    )}
                </TrayButton>
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: auto;   
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
    /* max-height: 25%; */
    overflow-y: scroll;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InnerContainer = styled.div`
    width: 69%;
    margin: 0 .5%;
    height: auto;
    flex-direction: column;
    height: auto;
    display: flex;
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
`;
