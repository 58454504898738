import React, { useState } from 'react';
import Input from '../../dashboard/fields/Input.js';
import { Submit } from '../../dashboard/forms/styles/formStyles';
import SponsorDisplay from './sponsorDisplay.js';
import { findDonor} from '../../dashboard/APIResources';

export default function FindShareID(props) {
    const [ first_name, setFirstName ] = useState(null);
    const [ last_name, setLastName ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ response, setResponse ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        await findDonor({ first_name: first_name, last_name: last_name, email: email }).then(res => {
            console.log(res);
            setResponse(res);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    };

    return (
            <div style={{width: '45%', margin: '5px 0 0 5px', borderStyle: 'dotted', borderWidth: '.5px', padding: '5px'}}>
                <Input name="first_name" label="First Name" value={first_name} onChange={(e) => { setFirstName(e.target.value) }} />
                <Input name="last_name" label="Last Name" value={last_name} onChange={(e) => { setLastName(e.target.value) }} />
                <Input name="email" label="Email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                <button style={{fontSize: '16px', float: 'left', margin: '0 0 10px 0'}} onClick={ handleClick }> Find Donor </button>
                {(response === null ?  <></> :
                    <SponsorDisplay response={response} transactionID={props.transactionID}/>
                    // <MainDisplay response={response} />
                )}
                {(loading ? <p>LOADING</p> : <></>)}
            </div>
    );
}