import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Logo from './resources/images/SisterIndiaLogo.svg';

export default class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSignOut = this.handleSignOut.bind(this);
    }

    async handleSignOut(e) {
        if(this.props.authState === "signedIn") {
            await Auth.signOut().catch(err => console.error(err));
            this.props.onStateChange('signedOut', {});
        } 
    }
    render() {
        return (
            <div style={{ flexDirection: 'row', display: 'flex', position: 'relative', alignContent: 'center', justifyContent: 'flex-end', width: '100%', height: '50px', backgroundColor: '#c7d9d3' }}>
                <img style={{ margin: '5px', height: '40px', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} src={Logo} />
                {(this.props.authState === "signedIn" ? <button onClick={this.handleSignOut} style={{ margin: '5px' }}>Sign Out</button> : null)}
            </div>
        )
    }
}