import React, { useState } from 'react';
import { addRelationshipToTransaction } from '../dashboard/APIResources';
import FindShareID from './findShareId/index.js';
import { Row, Column } from '../dashboard/forms/styles/formStyles.js';
import Input from '../dashboard/fields/Input.js';
import UpdateButton from '../dashboard/buttons/updateButton';

export default function AddRelationshipToTransaction(props) {
    //transaction_id

    const [ share_id, setShare_id ] = useState(null);
    const [ search, setSearch ] = useState(false);

    return (
        <Column>
            <Row>
                <Column width={50}>
                    <Input name="share_id" value={ share_id } label="Sponsor ID to Share Transaction With" onChange={ (e) => { setShare_id(e.target.value) }} />
                </Column>
                <Column width={50}>
                    <UpdateButton onClick={() => addRelationshipToTransaction(props.transaction_id, share_id) }>Add To Transaction</UpdateButton>
                </Column>
            </Row>
            <Row>
                <button onClick={() => {setSearch(!search)}}> {(search ? 'Close Search' : 'Search Donors')} </button>
                {/* <button> Add New  </button> */}
            </Row>
            {(search ? <FindShareID transactionID={props.transaction_id}/> : <></>)}
        </Column>
    );
}