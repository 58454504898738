import Statistic from './statistics';

export function renderProjectInformation() {
    return (
        <></>
    )
}

export function renderStateInformation() {
    return (
        <></>
    )
}

export function renderClassInformation() {
    return ( 
        <></>
    )
}

export function renderStatistics(object, format) {
    return (
        <>
            {Object.keys(object).map((statistic) => {
                return(
                    <Statistic object={object[statistic]} name={statistic} />
                );
            })}
        </>
    );
}

export function arrangeRowsArr(array, rowPop) {
    var obj = [];
    var mod = array.length % rowPop;
    var rows = (array.length - mod) / rowPop;
    if(mod > 0) {
        rows++;
    } else {
        mod = rowPop;
    }
    var runningIndex = 0;
    for(var i = 1; i <= rows; i++) {
        var arr = [];
        if(i === rows) {
            for(var x = 0; x < mod; x++) {
                arr.push(array[runningIndex]);
                runningIndex++;
            }
        } else {
            for(var x = 0; x < rowPop; x++) {
                arr.push(array[runningIndex]);
                runningIndex++;
            }
        }
        obj.push(arr);
    }
    return(obj);
}



export function arrangeRowsObj(object, rowPop) {
    var arr = [];
    var keys = Object.keys(object);
    var mod = keys.length % rowPop;
    var rows = (keys.length - mod) / rowPop;
    if(mod > 0) {
        rows++;
    } else {
        mod = rowPop;
    }
    var runningIndex = 0;
    for(var i = 1; i <= rows; i++) {
        var obj = {};
        if(i === rows) {
            for(var x = 0; x < mod; x++) {
                obj[keys[runningIndex]] = object[keys[runningIndex]];
                runningIndex++;
            }
        } else {
            for(var x = 0; x < rowPop; x++) {
                obj[keys[runningIndex]] = object[keys[runningIndex]];
                runningIndex++;
            }
        }
        arr.push(obj);
    }
    return(arr);

}

export function promiseRowsArr(array, rowPop) {
    return( new Promise((resolve,reject) => {
        var obj = [];
        var mod = array.length % rowPop;
        var rows = (array.length - mod) / rowPop;
        if(mod > 0) {
            rows++;
        } else {
            mod = rowPop;
        }
        var runningIndex = 0;
        for(var i = 1; i <= rows; i++) {
            var arr = [];
            if(i === rows) {
                for(var x = 0; x < mod; x++) {
                    arr.push(array[runningIndex]);
                    runningIndex++;
                }
            } else {
                for(var x = 0; x < rowPop; x++) {
                    arr.push(array[runningIndex]);
                    runningIndex++;
                }
            }
            obj.push(arr);
        }
        resolve(obj);
    }));
}
