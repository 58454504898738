import styled from 'styled-components';

export const Container = styled.div`
    display: flex; 
    flex-direction: column; 
    width: 45%; 
    margin: 0 35%;
`;

export const PageMargin = styled.div`
    display: flex; 
    flex-direction: column; 
    width: 25%; 
    margin: 0 37.5%;
    @media only screen and (max-width: 800px) {
        width: 100%;
        margin: 0;
    }
`;

export const Submit = styled.button`
    width: 40%; 
    margin-right: auto;
    font-size: 20px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row; 
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => (props.width !== undefined ? props.width: 100)}%;
`;