import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import GetRandomColor from '../../styling/colors';


export default class Navigation extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <>
                <Header>
                    <BackButton onClick={this.props.backButton}><p style={{fontSize: '30px', height: '25px', margin: 0, padding: 0}}>&larr;</p> <p style={{fontSize: '12px', margin: 0, padding: 0}}>BACK</p></BackButton>
                </Header>
                <Body>
                    {this.props.children}
                </Body>
            </>
        )
    }
}

const Body = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align:center;
    margin: auto;
`;

const Header =  styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align:center;
    margin: auto;
`;

var BackButton = styled.button`
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    color: black;
    :hover {
        ${() => {
            let color = GetRandomColor(true)[0];
            return css`background-color: ${color};`;
        }}
        color: white;
        
    }
    /* :not():hover {
        background-color: transparent;
    } */
`;

const BackArrow = styled.p`
    font-size: 30px;
`;