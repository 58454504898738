import { v4 as uuidv4 } from 'uuid';
import {API, Auth} from 'aws-amplify';

export function getSponsorshipInformation(sponsor_id, dateRange) {
    return( new Promise(async (resolve, reject) => {
        let [upper_date, lower_date] = [null, null];
        if(dateRange !== null) {
            if((dateRange[0] !== undefined || dateRange[0] !== null) ||(dateRange[1] !== undefined || dateRange[1] !== null)) {
                lower_date = dateRange[0].toISOString().slice(0, 19).replace('T', ' ');
                upper_date = dateRange[1].toISOString().slice(0, 19).replace('T', ' ');
            }
        }
        const reportingInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }, 
            queryStringParameters: {
                "school_year_id": 1,
                "sponsor_id": sponsor_id,
                "lower_date_range": lower_date,
                "upper_date_range": upper_date
            }
        };
        API.get('ReportingAPI', '/students/sponsor', reportingInit).then((res) => {
            console.log(res);
            resolve(res); 
        }).catch((err) => reject(err));
    }));
}

export function getOrgSponsorshipInformation(organization_id, dateRange) {
    return( new Promise( async (resolve, reject) => {
        let [upper_date, lower_date] = [null, null];
        if(dateRange !== null) {
            if((dateRange[0] !== undefined || dateRange[0] !== null) ||(dateRange[1] !== undefined || dateRange[1] !== null)) {
                lower_date = dateRange[0].toISOString().slice(0, 19).replace('T', ' ');
                upper_date = dateRange[1].toISOString().slice(0, 19).replace('T', ' ');
            }
        }
        const reportingInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }, 
            queryStringParameters: {
                "school_year_id": 1,
                "organization_id": organization_id,
                "lower_date_range": lower_date,
                "upper_date_range": upper_date
            }
        };
        API.get('ReportingAPI', '/students/organization', reportingInit).then((res) => {
            resolve(res); 
        }).catch((err) => reject(err));
    }));
}

export function postTransaction(inputs, birthday) {
    return( new Promise( async (resolve,reject) => {
        const date = new Date().toISOString().slice(0, 19).replace('T', ' ');
            var trxID = uuidv4();
            if(birthday !== null) {
                birthday = birthday.toISOString().slice(0, 10);
            }
            const reportingInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }, 
            body: { 
                transaction_id: trxID,
                amount: inputs.amount,
                date_time: date,
                recurring: false,
                donor: {
                    first_name: inputs.first_name,
                    middle_name: inputs.middle_name,
                    last_name: inputs.last_name,
                    email: inputs.email,
                    street_1: inputs.street_1,
                    street_2: inputs.street_2,
                    city: inputs.city,
                    state: inputs.state,
                    zip_code: inputs.zip_code,
                    country: inputs.country,
                    birthday: birthday
                }
            }
        };
        API.post('ReportingAPI', '/transactions', reportingInit).then((res) => { 
            resolve(res); 
        }).catch((err) => reject(err));  
    }))
}

export function findDonor(inputs) {
    return new Promise( async (resolve, reject) => {
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            queryStringParameters: {
                "first_name": inputs.first_name,
                "last_name": inputs.last_name,
                "email": inputs.email
            }
        };
        API.get('ReportingAPI', '/sponsors/search', req).then((res) => {
            resolve(res);
        }).catch((err) => reject(err));
    });
}

export function updateProjectInformation(inputs, projectID) {
    return( new Promise( async (resolve, reject) => {
        var bod = {};
        if(isReal(inputs.manager_alias)) {
            bod.manager_alias = inputs.manager_alias;
        }
        if(isReal(inputs.project_alias)) {
            bod.project_alias = inputs.project_alias;
        }
        if(isReal(projectID)) {
            bod.project_id = projectID;
            console.log(bod)
            const req = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
                body: bod
            };
            API.put('ReportingAPI', '/projects', req).then((res) => {
                resolve(res);
            }).catch((err) => reject(err));
        } else {
            reject('project_id is null');
        }

    }))
}

export function updateStudentAlias(inputs) {
    return( new Promise( async (resolve, reject) => {
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            body: {
                student_id: inputs.student_id,
                alias: inputs.alias
            }
        };
        API.put('ReportingAPI', '/students/alias', req).then((res) => {
            resolve(res);
        }).catch((err) => reject(err));
    }))
}

export function getProjectInformation(inputs) {
    return( new Promise( async (resolve,reject) => {
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            queryStringParameters: {
                "project_id": inputs.project_id
            }
        };
        API.get('ReportingAPI', '/projects', req).then((res) => {
            resolve(res);
        }).catch((err) => reject(err));
    }))
}

export function postArticle(inputs, object, plainText, categories) {
    return( new Promise( async (resolve, reject) => {
        var pub = false;
        if(isReal(inputs.public)) {
            pub = inputs.public;
        }
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            body: {
                title: inputs.title,
                type: inputs.type,
                categories: categories,
                public: pub,
                plain_text: plainText,
                article_object: JSON.stringify(object),
                state_id: inputs.state_id,
                project_id: inputs.project_id,
                project_code: inputs.project_code
            }
        };
        API.post('ReportingAPI', '/articles', req).then((res) => {
            resolve(res);
        }).catch((err) => reject(err));
    }))
}

export function searchOrganizationNames(name) {
    return new Promise( async (resolve, reject) => {
        const req = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          },
          queryStringParameters: {
              "q": name
          }
        };
        API.get('ReportingAPI', '/organizations/search', req).then(res => {
            resolve(res);
        }).catch(err => reject(err))
    });
}

export function getSponsorInformation(sponsorID) {
    return new Promise( async (resolve, reject) => {
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            queryStringParameters: {
                "sponsor_id": sponsorID
            }
        };
        API.get('ReportingAPI', '/sponsors', req).then(res => {
            resolve(res);
        }).catch(err => reject(err))
    });
}

export function removeRelationshipFromTransaction(transactionID) {
    return new Promise( async (resolve, reject) => {
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            body: {
                transaction_id: transactionID
            }
        };
        API.put('ReportingAPI', '/transactions/remove-relationship', req).then(res => {
            console.log(res);
            resolve({statusCode: 200}); //lul
        }).catch(err => {
            console.log(err);
            reject({statusCode: 500}); //also lul
        });
    });
}

export function addRelationshipToTransaction(transactionID, shareID) {
    return new Promise( async (resolve, reject) => {
        const req = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            body: {
                transaction_id: transactionID,
                share_id: shareID
            }
        };
        API.put('ReportingAPI', '/transactions/add-relationship', req).then(res => {
            console.log(res);
            resolve({statusCode: 200});
        }).catch(err => {
            console.log(err);
            reject({statusCode: 500});
        });
    });
}

function isReal(val) {
    if(val !== undefined && val !== "" && val !== null) {
      return true;
    }
    return false;
}