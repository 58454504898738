import { Label, InputRow } from 'aws-amplify-react';

export default function Input(props) {
    /*
    props
        label
        name
        value
        onChange()
    */
    return (
        <div style={{display: "flex", flexDirection: "row", width: '100%', justifyContent: "space-between", marginBottom: ".5vh"}}>
            <Label style={{width: 'auto', alignSelf:'flex-start'}}>{props.label}: </Label> <InputRow name={props.name} id={props.name} value={props.value} type="text" key={props.name} onChange={props.onChange} style={{width: "50%"}} />
        </div>
    );
}