import React, { Component, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Input from '../fields/Input';
import SponsorDisplay from '../displays/SponsorDisplay';
import Navigation from '../forms/styles/pageNavigation';
import {PageMargin} from '../forms/styles/formStyles';
import { getSponsorshipInformation, getOrgSponsorshipInformation } from '../APIResources';
import StudentDisplay from '../displays/StudentDisplay';
import AJV from 'ajv';
import { DashContext } from '../../../context';
import NormalButton from '../buttons/FancyButton';
const ajv = new AJV();

function DateRange(props) {
    const [ date, onChange ] = useState( null );
    
    function handleChange(value) {
        props.onChange(value);
        onChange(value);
    }

    return (
        <>
            <Calendar name="date-range" onChange={handleChange} selectRange={true} value={date} allowPartialRange={false} />
        </>
    )
}

export default class GetSponsorshipInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: null,
            response: null,
            displayedObj: null,
            displayedRelationship: null,
            donorUnit: "Sponsor",
            loading: false,
            db_id: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formErrors = this.formErrors.bind(this);
        this.swapObject = this.swapObject.bind(this);
        this.donorUnitChange = this.donorUnitChange.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.getAttribute('name')]: e.target.value});
        console.log(this.state.db_id)
    }
    async handleSubmit() {
        if(this.formErrors()) {
            setTimeout(() => {
                if(this.state.response !== null) {
                    this.setState({loading: true, response: null, displayedObj: null, displayedRelationship: null});
                } else {
                    this.setState({loading: true});
                }
            }, 100);
            if(this.context.inputLabel === null) {
                if(this.state.donorUnit === "Sponsor") {
                    await getSponsorshipInformation(this.state.db_id, this.state.dateRange).then((res) => {
                        console.log(res);
                        this.setState({ response: res, loading: false, sponsorInfo: res.sponsor_information });
                    }).catch(err => {console.log(err); this.setState({ loading: false }); });
                } else {
                    await getOrgSponsorshipInformation(this.state.db_id, this.state.dateRange).then((res) => {
                        console.log(res);
                        this.setState({ response: res, displayedObj: res.sponsorships, loading: false, sponsorInfo: res.organization_information });
                    }).catch(err => {console.log(err); this.setState({loading: false }); });
                }
            } else {
                if(this.context.inputLabel === "sponsor_id") {
                    await getSponsorshipInformation(this.context.inputValue, this.state.dateRange).then((res) => {
                        console.log(res);
                        this.setState({ response: res, loading: false, db_id: this.context.inputValue, donorUnit: 'Sponsor', sponsorInfo: res.sponsor_information });
                        this.context.passValue(null,null);

                    }).catch(err => { console.log(err); this.setState({ loading: false }); });
                } else {
                    await getOrgSponsorshipInformation(this.context.inputValue, this.state.dateRange).then((res) => {
                        console.log(res);
                        this.setState({ response: res, displayedObj: res.sponsorships, loading: false, db_id: this.context.inputValue, donorUnit: 'Organization', sponsorInfo: res.organization_information  });
                        this.context.passValue(null,null);
                    }).catch(err => { console.log(err); this.setState({ loading: false }); });
                }
            }
        } else {
            window.alert("Please fill out the required fields");
        }
    }
    dateChange(dateRange) {
        this.setState({dateRange: dateRange})
    }
    donorUnitChange(e) {
        this.setState({donorUnit: e.target.value});
    }
    formErrors() {
        // const validator = ajv.compile(errorSchema);
        // return(validator(this.inputs));
        return true;
    }
    swapObject(obj) {
        if(obj.share_information !== undefined && obj.share_information !== null) {
            this.setState({displayedObj: obj, displayedRelationship: obj.share_information});
        } else {
            this.setState({displayedObj: obj});
        }
    }
    static contextType = DashContext;

    render() {
        return (
            <>
                <PageMargin>
                    {(this.context.toolTip ?
                        <>
                            <div style={{display:'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                    <button onClick={() => this.setState(prevstate => ({displayDR: !prevstate.displayDR}))} style={{width: '40%'}}>{this.state.displayDR ? 'Hide Date Range' : 'Display Date Range'}</button>
                                </div>

                                {(this.state.displayDR ? <DateRange onChange={this.dateChange}/>
                                : <></>)}
                            </div>
                            <br/>
                            <div style={{display: 'flex', justifyContent: 'right'}}>
                                <label>Find Sponsorship Info For A</label>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'right'}}>
                                <select style={{fontSize: '16px'}} onChange={this.donorUnitChange}>
                                    <option>Sponsor</option>
                                    <option>Organization</option>
                                </select>
                            </div>
                            <br/>
                            <Input label={`${this.state.donorUnit} ID`} name={"db_id"} onChange={this.handleChange}/>
                            <button disabled={this.state.loading} style={{width: '40%'}} onClick={this.handleSubmit}> Get Sponsorship Information </button>
                            <br/>
                            <button disabled={this.state.loading} onClick={() => {(this.state.donorUnit === "Sponsor" ? this.context.changePage(3) : this.context.changePage(5))}}> Search For {this.state.donorUnit}s </button>
                        </>
                        :  
                        <>
                            <div style={{display:'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                    <button onClick={() => this.setState(prevstate => ({displayDR: !prevstate.displayDR}))} style={{width: '40%'}}>{this.state.displayDR ? 'Hide Date Range' : 'Display Date Range'}</button>
                                </div>

                                {(this.state.displayDR ? <DateRange onChange={this.dateChange}/>
                                : <></>)}
                            </div>
                            <br/>
                            {
                                (this.context.inputLabel === "sponsor_id" ? 
                                        <Input label="Sponsor ID" value={this.context.inputValue} name="db_id" onChange={this.handleChange}/>
                                    :
                                    (this.context.inputLabel === "organization_id" ? 
                                        <Input label="Organization ID" value={this.context.inputValue} name="db_id" onChange={this.handleChange} />
                                        :
                                        <Input label="Sponsor ID" name="db_id" onChange={this.handleChange}/>
                                    )  
                                )
                            }
                            <button disabled={this.state.loading} style={{width: '40%'}} onClick={this.handleSubmit}> Get Sponsorship Information </button>
                        </> 
                    )}
                </PageMargin>
                <br/>
                <div>
                    {(this.state.response !== null ?
                        <>
                            <div style={{ width: '100%', margin: 'auto', borderStyle: 'dashed', borderWidth: '1px', padding: '5px', borderColor: 'grey' }}>
                                <SponsorDisplay response={this.state.response} displayedObj={this.state.displayedObj} displayedRelationship={this.state.displayedRelationship} sponsor_information={this.state.sponsorInfo} />
                            </div>

                            {(this.state.response.sponsorships.result !== undefined ? (this.state.response.sponsorships.result.length !== 0 ? 
                                <>
                                    <button onClick={() => { this.swapObject(this.state.response.sponsorships) }}>Unshared Sponsorships</button>
                                </> 
                            : <></>) : <></>)}

                            {(this.state.response.shared_sponsorships !== undefined ? (this.state.response.shared_sponsorships.length !== 0 ? 
                                (this.state.response.shared_sponsorships.map((obj,key) => {
                                    return (
                                        <>
                                            <button key={key} onClick={() => { this.swapObject(obj) }}>{obj.share_information.first_name} shared with {this.state.response.sponsor_information.first_name}</button>
                                        </>
                                    );
                                }))
                            : <></>) : <></>)} 

                            {(this.state.response.sponsored_shared_sponsorships !== undefined ? (this.state.response.sponsored_shared_sponsorships.length !== 0 ?
                                this.state.response.sponsored_shared_sponsorships.map((obj, key) => {
                                    return (
                                        <> 
                                            <button key={key} onClick={() => { this.swapObject(obj) }}>{this.state.response.sponsor_information.first_name} shared with {obj.share_information.first_name}</button>
                                        </>
                                    );
                                }) 
                            : <></>) : <></>)}
 
                            {(this.state.displayedObj !== null ? 
                                <StudentDisplay response={this.state.displayedObj} /> 
                            : 
                                <></>
                            )}
                        </>
                    : (this.state.loading ? <p> Loading </p> : <></>))}
                </div>
            </>
        );
    }
}

const errorSchema = {
    "type": "object",
    "anyOf": [
        {
            "properties": {
                "first_name": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 35
                },
                "last_name": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 35
                },
                "email": {
                    "type": "string",
                    "minLength": 1,
                    "maxLength": 255
                }
            },
            "required": ["first_name", "last_name"]
        },
        {
            "properties": {
                "sponsor_id": {
                    "type": "string",
                    "minLength": 36,
                    "maxLength": 36
                },
            },    
            "required": ["sponsor_id"]
        }

    ]
};