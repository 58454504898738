import React, {Component} from 'react';
import NormalButton from '../buttons/FancyButton';

export default class Student extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            object: null,
            cts: null,
            percentage: ''
        };
    }
    static getDerivedStateFromProps(newprops, state) {
        if(state.object !== newprops.object || state.cts !== newprops.cts) {
            console.log(newprops.object);
            var amt;
            if(newprops.object.amount === undefined) {
                amt = newprops.object.student_information.amount;
            } else {
                amt = newprops.object.amount;
            }
            let percent = `${((amt / newprops.cts)*100).toFixed(2)}%`;
            return({
                percentage: percent,
                object: newprops.object,
                cts: newprops.cts
            });
        }
    }
    render() {
        return (
            <>
                {(this.props.format === 1 ?
                    <>
                        <NormalButton inactive={this.props.inactive} onClick={ () => { this.props.onClick(this.props.object, this.props.format) }} alias={["Student", this.props.object.student_information.alias]} ID={["Student ID", this.props.object.student_information.student_id]} title={this.state.percentage} stateAbbr={this.props.object.state_information.abbreviation} name="student"/>
                    </>
                    : 
                    <>
                        <NormalButton inactive={false} onClick={ () => { this.props.onClick(this.props.object, this.props.format)}} alias={["Student", this.props.alias]} information={this.props.information} ID={["Student ID", this.props.ID]} stateAbbr={this.state.percentage} name="student" />
                    </>
                )} 
            </>
        )
    }
}