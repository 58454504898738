import React, {useState} from 'react';
import styled from 'styled-components';
import IndiaStates from './IndiaStates';
import { Row} from '../dashboard/forms/styles/formStyles';

export default function ExpandableMap(props) {
    const [ expanded, setExpanded ] = useState(false);
    return(
        <TransparentContainer expanded={expanded}>
        <Container expanded={expanded}>
            <Row>
                <ToggleExpand onClick={() => setExpanded(!expanded)}>
                    {(expanded ? 
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><path d="M22,3.41l-5.29,5.29L20,12h-8V4l3.29,3.29L20.59,2L22,3.41z M3.41,22l5.29-5.29L12,20v-8H4l3.29,3.29L2,20.59L3.41,22z"/></svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71"/></svg>
                    )}
                </ToggleExpand>
            </Row>
            <InnerContainer expanded={expanded}>
                <IndiaStates states={props.states}/>
            </InnerContainer>
        </Container>
        </TransparentContainer>
    )
}

const TransparentContainer = styled.div`
    position: ${props => props.expanded ? 'fixed' : 'relative'};
    z-index: ${props => props.expanded ? 10 : 'inherit'};
    left: ${props => props.expanded ? 0 : 'unset'};
    top:  ${props => props.expanded ? 0 : 'unset'};
    width:  ${props => props.expanded ? '100%' : 'auto'};
    height:  ${props => props.expanded ? '100%' : 'auto'};
    background-color: rgba(255,255,255,.5);
    text-align: center;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: ${props => props.expanded ? '90vh' : 'auto'};
    width: ${props => props.expanded ? 'fit-content' : 'auto'};
    margin: ${props => props.expanded ? 'auto' : '0'};
    padding: ${props => props.expanded ? '2.5vh' : '0'};
    border: ${props => props.expanded ? '1px solid lightgrey' : 'none'};
    background-color: white;
`;
const InnerContainer = styled.div`
    position: relative;
    height: ${props => props.expanded ? '90vh' : '100%'};
    width: 100%;
    display: flex;
`;

const ToggleExpand = styled.div`
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    display: flex;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    align-self: right;
    background-color: white;

    :hover {
        background-color: lightgrey;
    }
`;