import React, { Component } from 'react';
import styled from 'styled-components';
import { DashContext } from '../../../../context';
import  UpdateProjectInformation from '../updateProjectInformation/updateProjectInformation';
import Input from '../../fields/Input';

const changeables = [ "project_alias", "manager_alias" ];

export default class ProjectDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            object: null
        };
        this.handleAliasUpdate = this.handleAliasUpdate.bind(this);
    }
    
    static contextType = DashContext;

    static getDerivedStateFromProps(newprops, state) {
        if(newprops.object !== state.object) {
            console.log(newprops.object);
            return({
                object: newprops.object
            });
        } else {
            return({
                object: state.object
            });
        }
    }

    handleAliasUpdate(status, value, el, id) {
        let stte = this.state;
        let obj = this.state.object;
    
        if(status) {
            obj[el] = value;
            console.log(value);
        }
        stte.object = obj;
        stte[id] = false;
        this.setState( stte );
    }

    changeAble(name, id) {
        var index = 0;
        for(const change of changeables) {
            index++;
            if(name === change) {
                if(this.state.object[name] === null) {
                    return (
                        <Row>
                            <LargeText><Title>{name.replace(/_/g, ' ')}</Title> {(!this.state[id] ? this.state.object[name] : '')} </LargeText>
                            {(this.state[id] ? 
                                <UpdateProjectInformation projectID={this.props.projectID} onClick={(value,status) => this.handleAliasUpdate(value,status,name,id)} updateItems={[name]} />
                            :
                                <Button onClick={() => {this.setState({ [id]: true })}}>ADD {name.replace(/_/g, ' ').toUpperCase()}</Button>
                            )}
                        </Row>
                    )
                } else {
                    return (
                        <Row>
                            <LargeText><Title>{name.replace(/_/g, ' ')}</Title>: {(!this.state[id] ? this.state.object[name] : '' )} </LargeText>
                            {(!this.state[id] ?
                                <Button onClick={() => {this.setState({ [id]: true })}}>CHANGE {name.replace(/_/g, ' ').toUpperCase()}</Button>
                            :
                                <UpdateProjectInformation projectID={this.props.projectID} onClick={(status, value) => { this.handleAliasUpdate(status, value, name, id)}} updateItems={[name]} />
                            )}
                            
                        </Row>
                    );
                }
            } else if(index === changeables.length) {
                return (
                    <>
                        {(this.state.object[name] === null ?
                            <Row>
                                <LargeText><Title>{name.replace(/_/g, ' ')}</Title>: Nothing Assigned</LargeText>
                            </Row>
                        :    
                            <Row>
                                <LargeText> <Title>{name.replace(/_/g, ' ')}</Title>: {this.state.object[name]}</LargeText>
                            </Row>
                        )}
                    </>
                );
            }
        }
    }

    render() {
        return(
            <Dash>
                <Column>
                    {(this.state.object !== null ? 
                        Object.keys(this.state.object).map((name, id) => {
                            return(
                                <>
                                    {this.changeAble(name, id)}
                                </>
                            )
                        })
                    :
                        <></>
                    )}
                </Column>
            </Dash>
        )
    }
}

const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`;

const Title = styled.span`
    font-weight: 800;
    text-transform: capitalize;
`;

const LargeText = styled.p`
    font-size: 15px;
    /* font-weight: 200; */
`;

const Dash = styled.div`
    border-style: dashed;
    border-width: 1px;
`;


const Button = styled.button`
    margin: auto 10px;
    height:15px;
`;