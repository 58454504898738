import React from 'react';
import styled from 'styled-components';
import GetRandomColor from '../styling/colors';
import ContextButton from './contextButton';



export default function NormalButton(props) {
    const [ backgroundColor, shadow, highlight ] = GetRandomColor(true);

    return (
            <OuterContainer background={backgroundColor} shadow={shadow} inactive={props.inactive} onClick={props.onClick}>
                <Row right={true} >
                    <Column>
                        <CardLabel inactive={props.inactive}>{props.name}</CardLabel>
                        <Row>
                            <SmallText inactive={props.inactive}><b>{props.ID[0]}:</b> {props.ID[1]}</SmallText>
                        </Row>
                    </Column>
                    <LargeText inactive={props.inactive}><b>{props.stateAbbr}</b></LargeText>
                </Row>
                <Row right={true}>
                    {(props.alias[1] === null ? <></> : (
                        props.alias[1] === undefined ? <></> :
                        <Column>
                            <ContextButton char="i">Names have been changed to protect the students and team in india</ContextButton>
                            <LargeText inactive={props.inactive}>{props.alias[0]}: <b>{props.alias[1]}</b></LargeText>
                        </Column>
                    ))}
                    <SmallText inactive={props.inactive}>{props.title}</SmallText>
                </Row>
            </OuterContainer>
    )
}


const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: fit-content;
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
    background-color: ${props => props.background};
    box-shadow: 2px 2px 2px 2px ${props => props.shadow};
    border-radius: 2px;
    margin: 5px;
`;

 

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => (props.right ? 'space-between': 'flex-start')};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-self: ${props => (props.right ? 'flex-end': 'flex-start')};
`;

const LargeText = styled.label`
    margin: 5px;
    font-size: 20px;
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
    @media only screen and (max-width: 1440px) {
        font-size: 14px;
    }
`;

const SmallText = styled.label`
    align-self: left;
    margin: 5px;
    font-size: 16px;    
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
    @media only screen and (max-width: 1440px) {
        font-size: 14px;
    }
`;

const CardLabel = styled.label`
    font-size: 16px;
    align-self: flex-start;
    margin: 5px;
    text-transform: uppercase;    
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
    @media only screen and (max-width: 1440px) {
        font-size: 14px;
    }
`;