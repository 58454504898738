import React, {Component} from 'react';
import NormalButton from '../buttons/FancyButton';

export default class Class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            percentage: null,
            object: null
        }
        this.handleClick = this.handleClick.bind(this);
    }
    static getDerivedStateFromProps(newprops, state) {
        if(state.object !== newprops.object) {
            var obj = newprops.object;
            var percent;
            var running = 0;
            var partial = 0;
            for(const student of obj.students) {
                if(student.amount === newprops.cts) {
                    running++;
                } else {
                    partial += student.amount;
                }
            }
            
            percent = `${((((running*newprops.cts) + partial) / (30 * newprops.cts))*100).toFixed(2)}%`;
            return({
                percentage: percent
            });
        }
    }
    handleClick() {
        this.setState(prevstate => ({active: !prevstate.active}));
    }

    render() {
        return (
            <>
            {(
                this.props.object.state_information === undefined ? 
                    <NormalButton active={this.state.active} onClick={() => { this.props.onClick(this.props.object, this.props.type) }} title={this.state.percentage} alias={["Teacher", this.props.object.class_information.teacher_alias]} ID={["Class ID", this.props.object.class_information.class_id]} stateAbbr="" name="Class"/>
                : 
                    <NormalButton active={this.state.active} onClick={() => { this.props.onClick(this.props.object, this.props.type) }} title={this.state.percentage} alias={["Teacher", this.props.object.class_information.teacher_alias]} ID={["Class ID", this.props.object.class_information.class_id]} stateAbbr={this.props.object.state_information.abbreviation} name="Class"/>
            )}
            </>
        )
    }
}                       