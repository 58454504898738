import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleFormSubmission = this.handleFormSubmission.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async handleFormSubmission(e) {
        e.preventDefault();
        if(this.inputs.newPassword === this.inputs.confirmPassword) {
            const newPassword = await this.inputs.newPassword;
            await Auth.completeNewPassword(this.props.user, newPassword);
            // await Auth.changePassword(this.props.user, this.props.oldPassword, newPassword);
        } else {
            this.props.changeMessage("Error: The passwords do not match", 0, true);
        }
    }
    
    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
        this.props.clearMessage();
    }

    render() {
        return(
                <div style={{width: '100%', textAlign: 'center'}}>
                    <br />
                    <label>Please change your password</label>
                    <br />
                    <form onSubmit={this.handleFormSubmission}>
                        <div style={{width: '100%', margin: 'auto'}}>
                            <input name="newPassword" key="newPassword" id="newPassword" type="password" placeholder="new password" onChange={this.handleChange} />
                        </div>
                        <div style={{width: '100%', margin: 'auto'}}>
                            <input name="confirmPassword" key="confirmPassword" id="confirmPassword" type="password" placeholder="confirm new password" onChange={this.handleChange} />
                        </div>
                        <div style={{width: '100%', margin: 'auto'}}>
                            <button type="submit" onClick={this.handleFormSubmission}>Change Password</button>    
                        </div>
                    </form>
                </div>
        )
    }
}