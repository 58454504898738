import React, { Component } from 'react';
import { DashContext } from '../../../context';
import { Container, Submit, PageMargin } from '../forms/styles/formStyles';
import Input from '../fields/Input';
import { searchOrganizationNames } from '../APIResources';
import Navigation from '../forms/styles/pageNavigation';
import OrganizationDisplay from '../displays/OrganizationDisplay';
import EmbeddedRequest from '../forms/embeddedRequest';

export default class FindOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
    }
    async handleSubmit() {
        await searchOrganizationNames(this.inputs.search).then(res => {
            console.log(res);
            this.setState({ response: res });
        }).catch(err => console.log(err))
    }
    static contextType = DashContext;

    render() {
        return (
            <>
                <PageMargin>
                    <Input name="search" label="Search Names" onChange={this.handleChange} />
                    <Submit onClick={this.handleSubmit}> Search Names </Submit>
                </PageMargin>
                {(this.state.response !== null ?
                    <>
                        <OrganizationDisplay response={this.state.response} />
                    </>
                    :
                    <></>)}
            </>
        );
    }
}