import React, { Component } from 'react';

export default class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expiration: this.props.expiration
        };
    }
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            if(this.props.expiration !== 0) {
                setTimeout(() => {
                    this.setState({expiration: this.props.expiration})
                }, this.props.expiration)
            }
        }
    }
    render() {
        return(
            <div style={{width: '100%', textAlign:'center'}}>
                <br/>
                <label style={{color: (this.props.errorStatus ? 'red' : 'black'), textAlign: 'center', margin: 'auto'}}>{this.props.message}</label>
            </div>
        );
    }
}