import React, { Component } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import TrayButton from '../buttons/TrayButton';
import { DashContext } from '../../../context';
import EmbeddedRequest from '../forms/embeddedRequest';

export default class OrganizationDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null,
            DisplayObject: null
        };
    }

    static getDerivedStateFromProps(newprops, state) {
        if(newprops.response !== state.response) {
            var output = [];
            var row;
            for(var i = 0; i < newprops.response.length; i++) {
                if((i % 4) === 0 ) {
                    if(i !== 0 ) {
                        output.push(row);
                    }
                    row = [];
                    row.push(newprops.response[i]);
                } else {
                    row.push(newprops.response[i]);
                }
            }
            output.push(row);
            return({
                response: newprops.response,
                DisplayObject: output
            })
        }
    }

    static contextType = DashContext;

    render() {
        return (
            <Container>
                {
                    this.state.DisplayObject.map((row) => {
                        return (
                            <Column>
                                {row.map((obj) => {
                                    return (
                                        <>
                                            <TrayButton tL={`${obj.organization.name}`} bL={`${obj.front_person.first_name} ${obj.front_person.last_name}`} tR={obj.organization.city} bR={obj.organization.state}>
                                                <Column>
                                                    {                                                    
                                                        Object.keys(obj.organization).map((key) => {
                                                            return (
                                                                <Row>
                                                                    {
                                                                        (key === 'organization_id' ? 
                                                                            <>
                                                                                <button style={{margin: '2px 10px', cursor: 'pointer'}} onClick={() => {copy(obj.organization[key])}}>Copy OrgID</button>
                                                                                <button style={{margin: '2px 10px', cursor: 'pointer'}} onClick={() => { this.context.passValue('organization_id', obj.organization[key]); this.context.flipToolTip(false); this.context.changePage(2); }}>Get Sponsorship Information For This Org</button>
                                                                            </>
                                                                        : 
                                                                            <>
                                                                                <h3 style={{fontSize: "16px", fontWeight: "500", margin: "0 5px"}}>{`${key.replace(/_/, ' ').toUpperCase()}:`}</h3>
                                                                                <h3 style={{fontSize: "16px", fontWeight: "300", margin: "0 5px"}}>{obj.organization[key]}</h3>
                                                                            </>
                                                                        )
                                                                    }
                                                                </Row>
                                                            );
                                                        })
                                                    }
                                                    <Row>
                                                        <EmbeddedRequest tL={'Organization Contact'} bL={`${obj.front_person.first_name} ${obj.front_person.last_name}`} dbID={obj.front_person.sponsor_id}/>
                                                    </Row>
                                                </Column>
                                            </TrayButton>
                                        </>
                                    )
                                })}
                            </Column>
                        )
                    })      
                }
            </Container>
        )
    }
}


const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: auto;   
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
    max-height: 25%;
    overflow-y: scroll;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;


const InnerContainer = styled.div`
    width: 69%;
    margin: 0 .5%;
    height: auto;
    flex-direction: column;
    height: auto;
    display: flex;
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
`;

