import React, { useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
    display: flex;
    cursor: pointer;
    font-size: 1em;
    :disabled {
        /* border-color: white;
        border-width: 1px;
        border-style: solid; */
        cursor: not-allowed;
        display: ${props => (props.success !== null ? 'none' : 'flex')};
    }
`;
const Parent = styled.div`
    display: flex;
    width: fit-content;
    flex-direction: column;
    font-size: 16px;
    border-style: solid;
    border-width: .5px;
    margin: 5px;
`;

export default function UpdateButton(props) {
    //children
    //onClick
    
    const [ loading, setLoading ] = useState(null);
    const [ success, setSuccess ] = useState(null);

    const handleClick = async () => {
        setLoading(true);
        await props.onClick().then(res => {
            setLoading(false);
            if(res.statusCode === 200) {
                setSuccess(true);
            } else {
                setSuccess(false);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false);
            setSuccess(false);
        }); 
    }

    return (
        <Parent>
            <Button disabled={( success !== null ? true : false )} onClick={handleClick}>
                {props.children}
            </Button>
            <p style={{display: (loading === null ? 'none' : 'flex' ), fontSize: '1em', textAlign: 'center', margin:'auto', cursor: 'default'}}> {( loading === null ? '' : ( loading ? 'LOADING' : ( success ? 'SUCCESS ' : 'FAILED' )))}</p>
        </Parent>
    );
}