import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
// import AppProvider from '../context/Provider';

export default class CustomSignIn extends Component {
    constructor(props) {
        super(props);
        this.handleFormSubmission = this.handleFormSubmission.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            error: ''
        };
        this._validAuthStates = ["signedOut", "signIn", "signedUp"];
    }

    async handleFormSubmission(e) {
        e.preventDefault();
        const username = this.inputs.username;
        const password = this.inputs.password;
        try{
           const user = await Auth.signIn(username, password);
            if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.props.onStateChange("newPassword", {});
                this.props.changePassword(user, password);
            } else {
                this.props.onStateChange("signedIn", {});
            }
            console.log(user);
        } catch(err) {
            console.log(err);
            if(err.code === "NotAuthorizedException") {
                this.props.changeMessage('Login Failed',0,true);
            } else if(err.code === "UserNotConfirmedException") {
                this.props.onStateChange("confirmSignUp", {});
            } else if(err.code === "UserNotFoundException") {
                this.props.changeMessage("Login Failed",0,true);
            } else {
                this.props.changeMessage('General Error',0,true);
            }
        }
    }

    handleChange(e) {
        this.inputs = this.inputs || {};
        const { name, value, type, checked } = e.target;
        const check_type = ["radio", "checkbox"].includes(type);
        this.inputs[name] = check_type ? checked : value;
        this.inputs["checkedValue"] = check_type ? value : null;
        this.props.clearMessage();
    }

    render() {
        return (
                <div style={{textAlign: 'center', width: '100%'}}>
                    {this._validAuthStates.includes(this.props.authState) && (
                        <div style={{width: '100%'}}>
                            <br />
                            <label>Please Sign In</label>
                            <br />
                            <form onSubmit={this.handleFormSubmission}>
                                <div style={{width: '100%', margin: 'auto'}}>
                                    <input name="username" key="username" id="username" type="text" onChange={this.handleChange} placeholder="username" />
                                </div>
                                <div style={{width: '100%', margin: 'auto'}}>
                                    <input name="password" key="password" id="password" type="password" onChange={this.handleChange} placeholder="password" />
                                </div>
                                <div style={{width: '100%', margin: 'auto'}}>
                                    <button onClick={this.handleFormSubmission} type="submit">Sign In</button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
        );
    }
}