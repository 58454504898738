import React, { Component } from 'react';
import styled from 'styled-components';
import Student from './student';
import Class from './class';
import Project from './project';
import SelectedDisplay from './SelectedDisplay';
import { arrangeRowsObj, arrangeRowsArr, renderStatistics } from './genericInformation';

const RowPop = 3;

export default class StudentDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Response: null,
            DisplayObject: null,
            DisplayType: null,
            format: null,
            SelectedButtons: [],
            active: true
        }
        this.displayFormatter = this.displayFormatter.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }
    static getDerivedStateFromProps(newprops, state) {
        if(newprops.response !== null && newprops.response.format !== undefined && newprops.response.result !== undefined && state.DisplayObject === null) {
            return({
                Response: newprops.response,
                DisplayType: newprops.response.format,
                DisplayObject: newprops.response.result,
                format: newprops.response.format,
            });
        }
    }
    handleClick(object, type) {
        var selectedBtns = this.state.SelectedButtons;
        if(this.state.DisplayType === 1) {
            let info = {
                state_name: object.state_information.name,
                svg_id: object.state_information.svg_id,
                project_alias: object.project_information.alias,
                project_id: object.project_information.project_id,
                class_alias: object.class_information.alias,
                class_id: object.class_information.class_id,
                teacher_alias: object.class_information.teacher_alias,
                project_manager_alias: object.project_information.manage_alias
            };
            selectedBtns.push({ParentObject: this.state.DisplayObject, ParentType: this.state.DisplayType, InfoObject: info, information: ['Project ID', object.project_information.project_id], alias: ['Student',object.student_information.alias], ID: ['Student ID', object.student_information.student_id] ,stateAbbr: object.state_information.abbreviation, name: 'student'});
            this.setState({DisplayObject: object.statistics, DisplayType: 0, SelectedButtons: selectedBtns});
        } else if(this.state.DisplayType === 2) {
            var cts = this.state.localCts
            if(this.state.format === this.state.DisplayType) {
                cts = object.school_year.cost_to_sponsor;
                let info = {
                    state_name: object.state_information.name,
                    svg_id: object.state_information.svg_id,
                    project_alias: object.project_information.alias,
                    project_id: object.project_information.project_id,
                    class_alias: object.class_information.alias,
                    teacher_alias: object.class_information.teacher_alias,
                };
                for(const stat of Object.keys(object.statistics)) {
                    if(object.statistics[stat].type === "1") {
                        info[stat] = `${object.statistics[stat].value}`;
                    } else {
                        info[stat] = `${((object.statistics[stat].value / object.statistics[stat].students) * 100).toFixed(2)}%`;
                    }
                }
                selectedBtns.push({ParentObject: this.state.DisplayObject, ParentType: this.state.DisplayType, InfoObject: info, alias: ["Class Alias", object.class_information.alias], ID: ["Class ID", object.class_information.class_id], stateAbbr: '', name: 'class'});
            } else {
                let info = {
                    class_alias: object.class_information.alias,
                    class_id: object.class_information.class_id,
                    teacher_alias: object.class_information.teacher_alias,
                };
                selectedBtns.push({ParentObject: this.state.DisplayObject, ParentType: this.state.DisplayType, InfoObject: info, alias: ["Class Alias", object.class_information.alias], ID: ["Class ID", object.class_information.class_id], stateAbbr: '', name: 'class'});
            }            
            this.setState({DisplayObject: object.students, DisplayType: 1, SelectedButtons: selectedBtns, localCts: cts});
        } else if(this.state.DisplayType === 3) {
            let info = {};
            var cts = object.school_year.cost_to_sponsor;
            for(const stat of Object.keys(object.statistics)) {
                if(object.statistics[stat].type === "1") {
                    info[stat] = `${object.statistics[stat].value}`;
                } else {
                    info[stat] = `${((object.statistics[stat].value / object.statistics[stat].students) * 100).toFixed(2)}%`;
                }
            }
            info.state_name = object.state_information.name;
            info.svg_id = object.state_information.svg_id;
            selectedBtns.push({ParentObject: this.state.DisplayObject, ParentType: this.state.DisplayType, InfoObject: info, alias: ["Project Alias", object.project_information.alias], ID: ["Project ID", object.project_information.project_id], stateAbbr: object.state_information.abbreviation, name: 'project'});
            this.setState({DisplayObject: object.classes, DisplayType: 2, SelectedButtons: selectedBtns, localCts: cts});
        }
    }
    handleBack() {
        let selectedBtns = this.state.SelectedButtons;
        if(selectedBtns.length > 0) {
            let dsplyObj = selectedBtns[selectedBtns.length - 1].ParentObject;
            let type = selectedBtns[selectedBtns.length - 1].ParentType;
            selectedBtns.pop();
            this.setState({
                DisplayObject: dsplyObj,
                DisplayType: type,
                SelectedButtons: selectedBtns
            });
        }
    }
    displayFormatter() {
        if(this.state.DisplayType !== null && this.state.DisplayType !== undefined && this.state.DisplayObject !== null && this.state.DisplayObject !== undefined && this.state.DisplayObject.length !== 0) {
            var obj = arrangeRowsArr(this.state.DisplayObject, RowPop);
            if(this.state.DisplayType === this.state.format) {
                if(this.state.DisplayType === 1) {
                    return (
                        <>
                            {obj.map((arr) => {
                                return (
                                    <Row>
                                        {arr.map((object) => {
                                            return (
                                                <Student onClick={this.handleClick} object={object} cts={object.school_year.cost_to_sponsor} format={1}/>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                        </>
                    );
                } else if(this.state.DisplayType === 2) {
                    return (
                        <>
                            {obj.map((arr) => {
                                return (
                                    <Row>
                                        {arr.map((object) => {
                                            return (
                                                <Class onClick={this.handleClick} cts={object.school_year.cost_to_sponsor} object={object} format={2}/>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                        </>
                    );
                } else if(this.state.DisplayType === 3) {
                    return (
                        <>
                            {obj.map((arr, key) => {
                                return (
                                    <Row key={key}>
                                        {arr.map((object) => {
                                            return (
                                                <Project onClick={this.handleClick} object={object} cts={object.school_year.cost_to_sponsor} format={3}/>
                                            );
                                        })}
                                    </Row>
                                )
                            })}
                        </>
                    );
                } else {
                    return (
                        <>
                            <p>Unrecognized Format</p>
                        </>
                    );
                }               
            } else {
                if(this.state.DisplayType === 1) {
                    let arr = arrangeRowsArr(this.state.DisplayObject, RowPop);
                    return (
                        <>
                            {arr.map((obj) => {
                                return (
                                    <Row>
                                        {obj.map((student) => {
                                            return (
                                                <Student alias={student.alias} ID={student.student_id} cts={this.state.localCts} object={student} format={0} onClick={() => {}}/>
                                                // <TrayButton alias={student.alias} ID={student.student_id} object={student} format={0}/>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                        </>
                    )
                } else if(this.state.DisplayType === 2) {
                    let arr = arrangeRowsArr(this.state.DisplayObject, RowPop);
                    let cts;
                    if(this.state.localCts !== null && this.state.localCts !== undefined) {
                        cts = this.state.localCts;
                    }
                    return (
                        <> 
                            {arr.map((obj) => {
                                    return (
                                        <Row>
                                            {obj.map((CLASS) => {
                                                if(cts === null || cts === '') {
                                                    cts = CLASS.school_year.cost_to_sponsor;
                                                }
                                                return (
                                                    <Class onClick={this.handleClick} cts={cts} object={CLASS} />
                                                )
                                            })}
                                        </Row>
                                    )
                                })}
                        </>
                    )
                } else if(this.state.DisplayType === 0) {
                    console.log(this.state.DisplayObject);
                    let arr = arrangeRowsObj(this.state.DisplayObject, 4);
                    return (
                        <>  
                            {arr.map((obj) => {
                                return (
                                    <Row>
                                        { renderStatistics(obj, this.state.DisplayType) }
                                    </Row>
                                )
                            })}
                        </>
                    );
                }
            }
        } else {
            return(
                <>
                    <p>No Data</p>
                </>
            );
        }
    }
    render() {
        return (
            <Container>
                <Row>
                    <SelectedDisplay objects={this.state.SelectedButtons} onBack={this.handleBack}/>
                    <InnerContainer>
                        {this.displayFormatter()}              
                    </InnerContainer>
                </Row>
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: auto;   
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
`;
const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row; 
`;
const InnerContainer = styled.div`
    width: 69%;
    margin: 0 .5%;
    height: auto;
    flex-direction: column;
    height: auto;
    display: flex;
    border-style: dashed;
    border-width: 1px;
    padding: 5px;
    border-color: grey;
`;
