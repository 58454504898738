import { generateRandomString } from '@aws-amplify/core';
import React, { Component } from 'react';
import styled from 'styled-components';
import GetRandomColor from '../styling/colors';

export default class TrayButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trayDisplay: false
        };
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        let [ background, shadow, highlight ] = GetRandomColor(); 
        this.setState({ backgroundColor: background, shadowColor: shadow, highlightColor: highlight });
    }
    handleClick() {
        this.setState(prevstate => ({
            trayDisplay: !prevstate.trayDisplay
        }));
        try {
            this.props.onClick();
        } catch(err) {
            console.log(err);
        }
    }
    render() {
        return(
            <Container trayDisplay={this.state.trayDisplay}>
                <ButtonContainer background={this.state.backgroundColor} shadow={this.state.shadowColor} onClick={this.handleClick}>
                    <Row>
                        <LargeText>
                            {this.props.tL}
                        </LargeText>
                        <SmallText>
                            {this.props.tR}
                        </SmallText>
                    </Row>
                    <Row>
                        <SmallText>
                            {this.props.bL}
                        </SmallText>
                        <LargeText>
                            {this.props.bR}
                        </LargeText>
                    </Row>
                </ButtonContainer>
                <Tray background={this.state.highlightColor} shadow={this.state.backgroundColor} trayDisplay={this.state.trayDisplay}>
                    {this.props.children}
                </Tray>
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    height: auto;
    width: auto;
    flex-direction: column;
    margin-bottom: ${props => (props.trayDisplay ? '10px' : '0px')}
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Tray = styled.div`
    display: ${props => (props.trayDisplay ? 'flex' : 'none')};
    flex-direction: row;
    align-self: center;
    width: 93%;
    margin: 5px;
    margin-top: 2.5px;
    border-radius: 5px;
    background-color: ${props => props.background};
    box-shadow: 2px 2px 2px 2px ${props => props.shadow};
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 95%;
    height: fit-content;
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
    background-color: ${props => props.background};
    box-shadow: 2px 2px 2px 2px ${props => props.shadow};
    border-radius: 2px;
    margin: 5px;
`;

const LargeText = styled.label`
    margin: 5px;
    font-size: 20px;
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
`;

const SmallText = styled.label`
    align-self: left;
    margin: 5px;
    font-size: 16px;    
    cursor: ${props => (props.inactive ? 'auto' : 'pointer')};
`;