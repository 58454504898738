import React from 'react';
import { Column, Row } from '../../dashboard/forms/styles/formStyles.js';
import { addRelationshipToTransaction } from '../../dashboard/APIResources.js';
import TrayButton from '../../dashboard/buttons/TrayButton';
import UpdateButton from '../../dashboard/buttons/updateButton';
import copy from 'copy-to-clipboard';

export default function SponsorDisplay(props) {
    // response
    // transaction_id
    
    return (
        <Column>
            {props.response.map((obj,key) => {
                return (
                    <TrayButton key={key} tL={`${obj.first_name} ${obj.last_name}`} bL={obj.email} tR={obj.city} bR={obj.state}>
                        <Column>
                            <UpdateButton onClick={() => addRelationshipToTransaction(props.transactionID, obj.sponsor_id)}>Add To Transaction</UpdateButton>
                            {Object.keys(obj).map((el,ky) => {
                                return (
                                    <Row>
                                        <h3 key={ky} style={{fontSize: "16px", fontWeight: "500", margin: "0 5px"}}>{`${el.replace(/_/, ' ').toUpperCase()}:`}</h3>
                                        <h3 key={ky} style={{fontSize: "16px", fontWeight: "300", margin: "0 5px"}}>{obj[el]}</h3>
                                        {((el === 'sponsor_id' || el === 'email') ? 
                                            <button key={ky} style={{margin: '2px 10px', cursor: 'pointer'}} onClick={() => {copy(obj[el])}}>Copy to Clipboard</button>
                                        : <></>)}
                                    </Row>
                                );
                            })}
                        </Column>
                    </TrayButton>
                );
            })}
        </Column>
    );
}