import React, {Component} from 'react';
import styled, {keyframes, css} from 'styled-components';
import NormalButton from '../buttons/FancyButton.js';

export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            object: null,
            percent: null
        };
    }
    static getDerivedStateFromProps(newprops, state) {
        if(newprops.object !== state.object) {
            let running = 0;
            let partial = 0;
            for(const cls of newprops.object.classes) {
                for(const student of cls.students) {
                    if(student.amount === newprops.cts) {
                        running++
                    } else {
                        partial += student.amount;
                    }
                }
            }
            let percentage = `${((((running*newprops.cts) + partial)/(150*newprops.cts))*100).toFixed(2)}%`;
            return { 
                object: newprops.object,
                percent: percentage
            };
        }
    }
    render() {
        return(
            <>
                <NormalButton alias={["Project Alias", this.props.object.project_information.project_alias]} ID={["Project ID", this.props.object.project_information.project_id]} title={this.state.percent} stateAbbr={this.props.object.state_information.abbreviation} active={this.state.active} onClick={() => { this.props.onClick(this.props.object)}} name="project" />
            </>
        )
    }
}

/*  
    [
        {
            state_information: {},
            project_information: {},
            statistics: {},
            class_information: {},
            student_information: {}
        }
    ]
*/


/*
    {
        project_information: {
            project_id,
            project_manager_alias,
            project_alias
        },
        state_information: {
            state_name,
            state_abbreviation,
            profile_image
        },
        statistics: {
            started_reading,
            hygiene,
            started_math
        },
        classes: [
            {
                class_information: {
                    class_id,
                    teacher_alias,
                    class_alias
                },
                students: [
                    {
                        student_id,
                        alias,
                        rng_seed
                    }
                ]
            }
        ]
    }
 */