import React, {useState} from 'react';
import styled from "styled-components";

export default function ContextButton(props) {
    //children
    //char
    const [ display, setDisplay ] = useState(false);
    return (
        <Container>
            <Button display={display} onClick={() => setDisplay(!display)}>
                <p>{props.char}</p>
            </Button>
            <Information display={display} onClick={() => setDisplay(false)}>{props.children}</Information>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 100px;
    width: fit-content;
    margin: 4px;
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    width: 1em;
    text-align: center;
    height: 1em;
    font-size: .1em;
    padding: .1em;
    color: white;
    font-style: italic;
    cursor: pointer;
    background-color: black;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    :hover {
        background-color: white;
        color: black;
    }
`;
const Information = styled.p`
    font-size: .12em;
    display: ${props => (props.display ? 'flex' : 'none')};
    cursor: pointer;
    margin: 5px;
    text-align: left;
`;