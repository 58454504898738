import React, {Component} from 'react';
import styled from 'styled-components'

export default class Statistic extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        console.log(this.props.object.type);
        if(this.props.object.value > 0) {
            if(this.props.object.type === "1") {
                return (
                    <Container>
                        <h3 style={{textTransform: 'capitalize'}}>{this.props.name.replace(/_/g, "\n")}</h3>
                        <h2>{this.props.object.value}</h2>
                    </Container>
                );
            } else if(this.props.object.type === "2") {
                return (
                    <Container>
                        <h3 style={{textTransform: 'capitalize'}}>{this.props.name.replace(/_/g, "\n")}</h3>
                        <h2>{((this.props.object.value / this.props.object.students)*100).toFixed(2)}%</h2>
                    </Container>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    }
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 5px;
    border-style: solid;
    border-color: grey;
`;
