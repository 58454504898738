import React, { useState } from 'react';
import { Row, Column } from '../forms/styles/formStyles';
import copy from 'copy-to-clipboard';
import { removeRelationshipFromTransaction } from '../APIResources';
import UpdateButton from '../buttons/updateButton';
import AddRelationshipToTransaction from '../../getSponsorshipInformation/addRelationshipToTransaction';

export default function SponsorDisplay(props) {
    // const [ moreInfo, setMoreInfo ] = useState( null );
    const [ displayTrx, setDispTrx ] = useState( false );

    return (
        <>
            {(props.sponsor_information ?
                <Row>
                    <div style={{backgroundColor: 'rgb(211,211,211)', display: 'flex', flexDirection: 'column', width:'fit-content', height: 'fit-content', padding: '5px', marginRight: '1%', borderRadius: '5px', boxShadow: '2px 2px 2px 2px rgb(191,191,191)'}}>
                        {Object.keys(props.sponsor_information).map((el, key) => {
                            if(props.sponsor_information[el]) {
                                return (
                                    <Row>
                                        <p key={key} style={{margin: '0', padding: '0'}}><span style={{fontWeight: 'bold'}}>{el.replace(/_/, ' ').toUpperCase()}:</span> {props.sponsor_information[el]}</p>
                                    </Row>
                                );
                            }
                        })}
                    </div>
                    <div style={{backgroundColor: 'rgb(241,241,241)', display: 'flex', flexDirection: 'column', width:'fit-content', height: 'fit-content', padding: '5px', marginRight: '1%', borderRadius: '5px', boxShadow: '2px 2px 2px 2px rgb(221,221,221)'}}>
                        {(props.displayedObj !== null ?
                            <Row>
                                <button style={{cursor:'pointer'}} onClick={() => { setDispTrx(!displayTrx) }}> {(props.displayedRelationship !== null ? 'Break Relationship' : 'Add Relationship' )} </button>
                                <br/>
                            </Row>
                        : "")}
                        <>
                            {( props.displayedRelationship !== null ?
                                Object.keys(props.displayedRelationship).map((el, key) => { 
                                    if(el === "sponsor_id") {
                                        return (
                                            <Row>
                                                <p key={key} style={{margin: '0', padding: '0'}}><span style={{fontWeight: 'bold'}}>{el.replace(/_/, ' ').toUpperCase()}:</span></p><button style={{margin: '0 5px 0 5px', cursor: 'pointer'}} onClick={() => {copy(props.displayedRelationship[el])}}> Copy </button>
                                            </Row>
                                        );
                                    } else {
                                        return (
                                            <Row>
                                                <p key={key} style={{margin: '0', padding: '0'}}><span style={{fontWeight: 'bold'}}>{el.replace(/_/, ' ').toUpperCase()}:</span> {props.displayedRelationship[el]}</p>
                                            </Row>
                                        );
                                    }
                                })
                            : '' )}
                        </>
                    </div>
                    {(props.displayedObj !== null ? 
                        <>
                            {(displayTrx ? 
                                <div style={{backgroundColor: 'rgb(231,231,231)', display: 'flex', flexDirection: 'column', width:'fit-content', height: 'fit-content', padding: '5px', marginRight: '1%', borderRadius: '5px', boxShadow: '2px 2px 2px 2px rgb(211,211,211)'}}>
                                    {props.displayedObj.transactions.map((el, key) => {
                                        return (
                                            <Row>
                                                <Column>
                                                    <p style={{textAlign:'left'}}><b>Transaction ID:</b> {el} </p>
                                                    {(props.displayedRelationship !== null ?                                          
                                                        <UpdateButton onClick={ () => removeRelationshipFromTransaction(el) }> Break Transaction </UpdateButton>
                                                    : <AddRelationshipToTransaction transaction_id={el}/> )}
                                                    
                                                </Column>
                                            </Row>
                                        );
                                    })}
                                </div>
                                :
                                <></>
                            )}
                        </>
                    :  <></>)}
                </Row>
            :
            <></>)}
        </>
    );
}