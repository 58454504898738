import React, { Component } from 'react';
import { Container, Column, Submit } from '../forms/styles/formStyles';
import Navigation from '../forms/styles/pageNavigation';
import { DashContext } from '../../../context';
import GetProjectInformation from '../forms/getProjectInformation/getProjectInformation';

export default class UpdateInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subdir: null
        };
        this.handleBack = this.handleBack.bind(this);
    }
    handleBack() {
        if(this.state.subdir === null) {
            this.context.backButton();
        } else {
            this.setState({subdir: null});
            this.inputs = {};
        }
    }
    static contextType = DashContext;

    render() {
        return(
            <Navigation backButton={this.handleBack}>
                    {(this.state.subdir === null ? 
                        <Container>
                            <Column>
                                <p>What kind of entry would you like to update?</p>
                                <button onClick={() => this.setState({ subdir: 1 })}> Project </button>
                                <br/>
                                <button onClick={() => this.setState({ subdir: 2 })}> Student </button>
                            </Column>
                        </Container>
                    : (this.state.subdir === 1 ? 
                        <Container>
                            <GetProjectInformation />
                        </Container> : <></>
                            
                    )
                    )}
            </Navigation>
        )
    }
}